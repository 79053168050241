@import "../modules/color";
@import "../modules/font";
@import "../modules/card";

.app-header {
  background-color: $bg-white;
  padding: 0px 20px 0px 20px;
  box-shadow: 4px 8px 15px 0 rgba(124, 124, 126, 0.04);
  border: solid 1px #d5d5d5;
  height: 60px;
  .wrapper {
    width: 90vw;
    margin: 0 auto;
    display: block;
    .logo-cntnr {
      padding-top: 10px;
      display: inline-flex;
      img {
        width: 60px;

        &:nth-child(2) {
          border-left: 1px solid #dddede;
          padding-left: 10px;
          width: 75px;
        }
        &:nth-child(1) {
          width: 120px;
        }
      }
      div {
        display: flex;
        flex-direction: row;
        padding-right: 20px;
        &.left-img {
          img {
            margin-top: -10px;
            display: block;
            border-right: none;
            width: 70px;
          }

        }
        .IDFCLogo {
          margin-left: 10px;
        }
      }
    }

    i {
      color: $text-navy-blue;
      font-size: 1rem;
      margin-right: 7px;
    }
    .text-content {
      @include poppins-normal;
      color: $text-navy-blue;
      padding-top: 16px;
      span {
        cursor: pointer;
      }
      text-align: right;
    }
    .stepper-row {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: space-between;
      .step {
        height: 60px;
        border-bottom: 3px solid transparent;
        @include vertical-center;
        flex-direction: row;
        width: 25%;
        &.active {
          border-bottom-color: $text-blue;
          color: $text-blue;
          .step-index {
            border-color: $text-blue;
            color: $text-blue;
            // background: $bg-white;
            @include vertical-center;
          }
          .step-name {
            color: $text-blue;
          }
        }
        &.completed {
          border-bottom-color: $text-blue;
          color: $text-blue;
          .step-name {
            color: $text-blue;
            @include poppins-bold;
            font-size: 13px;
          }
          .step-index {
            border: none;
            padding: 0;
            i {
              font-size: 22px;
            }
          }
        }
        .step-index {
          border: 1px solid transparent;
          @include poppins-semi-bold;
          padding-top: 1px;
          width: 20px;
          height: 20px;
          background: #eaf1f8;
          text-align: center;
          font-size: 11px;
          color: #8a93a5;
          border-radius: 3px;
          margin-right: 10px;
          i {
            width: 20px;
            height: 20px;
          }
        }
        .step-name {
          @include poppins-semi-bold;
          color: #8a93a5;
          font-size: 11px;
          cursor: pointer;
        }
      }
    }
  }
}
.router-layout {
  .container {
    background: $bg-light-blue;
    min-height: 80vmin;
  }
  @include poppins-normal;
}

.app-footer {
  background-color: $bg-white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  .tnc-cntnr {
    width: 90vw;
    box-shadow: 4px 8px 15px 0 rgba(41, 26, 204, 0.05);
    border: solid 1px #d5d9dc;
    background: $bg-light-blue;
    border-radius: 8px;
    max-height: 100px;
    overflow: auto;
    margin-bottom: 80px;
    &.payment-tnc {
      width: 100%;
      max-height: auto;
      box-shadow: none;
      border: none;
    }
    .title {
      @include poppins-bold;
      font-size: 1.2rem;
      color: $text-navy-blue;
      padding: 20px 20px 0px 20px;
    }
    .content {
      font-size: 11px;
      padding: 20px 30px;
      @include poppins-normal;
      .content-row {
        margin-bottom: 15px;
        b {
          @include poppins-semi-bold;
        }
      }
    }
    .beware-of-phone-calls {
      border: 1px solid #666666;
      padding: 10px;
      @include poppins-normal;
      width: 80%;
      font-size: 11px;
      display: block;
      margin: 0 auto 20px auto;
      .disclaimer-header {
        @include poppins-bold;
        margin-bottom: 10px;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 50%;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #05396e;
  border-radius: 35px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #05396e;
}

@media only screen and (max-width: 1024px) {
  body {
    .app-header {
      padding: 0px 10px;
      .wrapper {
        .text-content {
          text-align: left;
        }
        .logo-cntnr {
          .right-img {
            img {
              width: 60px;
              margin-top: 30px;
              display: block;
              &:nth-child(1) {
                margin-top: -10px;
                width: 130px;
                margin-left: 1vw;
              }
              &:nth-child(2) {
                margin-top: -5px;
                width: 80px;
                margin-left: 1vw;
              }
            }
          }
        }
      }
    }
    .app-footer {
      .tnc-cntnr {
        margin-bottom: 160px;
      }
    }
  }
}

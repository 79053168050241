$one-grid: (100% / 12);
@function calcWidth($col) {
  @return $col * (100% / 12);
}
.grid {
  margin: 0px !important;
  padding: 0px !important;
  vertical-align: top;
  display: inline-flex;
  & > div {
    width: 100%;
  }
  //responsive sizes
  &[xs='12'],
  &[md='12'],
  &[sm='12'],
  &[lg='12'] {
    width: calcWidth(12);
    display: inline-flex;
  }
  &[xs='11'],
  &[md='11'],
  &[sm='11'],
  &[lg='11'] {
    width: calcWidth(11);
    display: inline-flex;
  }
  &[xs='10'],
  &[md='10'],
  &[sm='10'],
  &[lg='10'] {
    width: calcWidth(10);
    display: inline-flex;
  }
  &[xs='9'],
  &[md='9'],
  &[sm='9'],
  &[lg='9'] {
    width: calcWidth(9);
    display: inline-flex;
  }
  &[xs='8'],
  &[md='8'],
  &[sm='8'],
  &[lg='8'] {
    width: calcWidth(8);
    display: inline-flex;
  }
  &[xs='7'],
  &[md='7'],
  &[sm='7'],
  &[lg='7'] {
    width: calcWidth(7);
    display: inline-flex;
  }
  &[xs='6'],
  &[md='6'],
  &[sm='6'],
  &[lg='6'] {
    width: calcWidth(6);
    display: inline-flex;
  }
  &[xs='5'],
  &[md='5'],
  &[sm='5'],
  &[lg='5'] {
    width: calcWidth(5);
    display: inline-flex;
  }
  &[xs='4'],
  &[md='4'],
  &[sm='4'],
  &[lg='4'] {
    width: calcWidth(4);
    display: inline-flex;
  }
  &[xs='3'],
  &[md='3'],
  &[sm='3'],
  &[lg='3'] {
    width: calcWidth(3);
    display: inline-flex;
  }
  &[xs='2'],
  &[md='2'],
  &[sm='2'],
  &[lg='2'] {
    width: calcWidth(2);
    display: inline-flex;
  }
  &[xs='1'],
  &[md='1'],
  &[sm='1'],
  &[lg='1'] {
    width: calcWidth$one-grid;
    display: inline-flex;
  }
  &[xs='0'],
  &[md='0'],
  &[sm='0'],
  &[lg='0'] {
    display: none;
  }
}
.table-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  //xs device
  .grid {
    &[xs='12'] {
      width: calcWidth(12) !important;
      display: inline-flex;
    }
    &[xs='11'] {
      width: calcWidth(11) !important;
      display: inline-flex;
    }
    &[xs='10'] {
      width: calcWidth(10) !important;
      display: inline-flex;
    }
    &[xs='9'] {
      width: calcWidth(9) !important;
      display: inline-flex;
    }
    &[xs='8'] {
      width: calcWidth(8) !important;
      display: inline-flex;
    }
    &[xs='7'] {
      width: calcWidth(7) !important;
      display: inline-flex;
    }
    &[xs='6'] {
      width: calcWidth(6) !important;
      display: inline-flex;
    }
    &[xs='5'] {
      width: calcWidth(5) !important;
      display: inline-flex;
    }
    &[xs='4'] {
      width: calcWidth(4) !important;
      display: inline-flex;
    }
    &[xs='3'] {
      width: calcWidth(3) !important;
      display: inline-flex;
    }
    &[xs='2'] {
      width: calcWidth(2) !important;
      display: inline-flex;
    }
    &[xs='1'] {
      width: calcWidth(1) !important;
      display: inline-flex;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  //sm device
  .grid {
    &[sm='12'] {
      width: calcWidth(12) !important;
      display: inline-flex;
    }
    &[sm='11'] {
      width: calcWidth(11) !important;
      display: inline-flex;
    }
    &[sm='10'] {
      width: calcWidth(10) !important;
      display: inline-flex;
    }
    &[sm='9'] {
      width: calcWidth(9) !important;
      display: inline-flex;
    }
    &[sm='8'] {
      width: calcWidth(8) !important;
      display: inline-flex;
    }
    &[sm='7'] {
      width: calcWidth(7) !important;
      display: inline-flex;
    }
    &[sm='6'] {
      width: calcWidth(6) !important;
      display: inline-flex;
    }
    &[sm='5'] {
      width: calcWidth(5) !important;
      display: inline-flex;
    }
    &[sm='4'] {
      width: calcWidth(4) !important;
      display: inline-flex;
    }
    &[sm='3'] {
      width: calcWidth(3) !important;
      display: inline-flex;
    }
    &[sm='2'] {
      width: calcWidth(2) !important;
      display: inline-flex;
    }
    &[sm='1'] {
      width: calcWidth(1) !important;
      display: inline-flex;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 2048px) {
  //md device
  .grid {
    &[md='12'] {
      width: calcWidth(12) !important;
      display: inline-flex;
    }
    &[md='11'] {
      width: calcWidth(11) !important;
      display: inline-flex;
    }
    &[md='10'] {
      width: calcWidth(10) !important;
      display: inline-flex;
    }
    &[md='9'] {
      width: calcWidth(9) !important;
      display: inline-flex;
    }
    &[md='8'] {
      width: calcWidth(8) !important;
      display: inline-flex;
    }
    &[md='7'] {
      width: calcWidth(7) !important;
      display: inline-flex;
    }
    &[md='6'] {
      width: calcWidth(6) !important;
      display: inline-flex;
    }
    &[md='5'] {
      width: calcWidth(5) !important;
      display: inline-flex;
    }
    &[md='4'] {
      width: calcWidth(4) !important;
      display: inline-flex;
    }
    &[md='3'] {
      width: calcWidth(3) !important;
      display: inline-flex;
    }
    &[md='2'] {
      width: calcWidth(2) !important;
      display: inline-flex;
    }
    &[md='1'] {
      width: calcWidth(1) !important;
      display: inline-flex;
    }
  }
}

@media only screen and (min-width: 2049px) {
  //lg device
  .grid {
    &[lg='12'] {
      width: calcWidth(12) !important;
      display: inline-flex;
    }
    &[lg='11'] {
      width: calcWidth(11) !important;
      display: inline-flex;
    }
    &[lg='10'] {
      width: calcWidth(10) !important;
      display: inline-flex;
    }
    &[lg='9'] {
      width: calcWidth(9) !important;
      display: inline-flex;
    }
    &[lg='8'] {
      width: calcWidth(8) !important;
      display: inline-flex;
    }
    &[lg='7'] {
      width: calcWidth(7) !important;
      display: inline-flex;
    }
    &[lg='6'] {
      width: calcWidth(6) !important;
      display: inline-flex;
    }
    &[lg='5'] {
      width: calcWidth(5) !important;
      display: inline-flex;
    }
    &[lg='4'] {
      width: calcWidth(4) !important;
      display: inline-flex;
    }
    &[lg='3'] {
      width: calcWidth(3) !important;
      display: inline-flex;
    }
    &[lg='2'] {
      width: calcWidth(2) !important;
      display: inline-flex;
    }
    &[lg='1'] {
      width: calcWidth(1) !important;
      display: inline-flex;
    }
  }
}

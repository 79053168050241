@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

@font-face {
  font-family: 'Poppins Normal Font';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins Normal Font'),
    url('../fonts/Poppins/Poppins-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins Regular Font';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins Regular Font'),
    url('../fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins SemiBold Font';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins SemiBold Font'),
    url('../fonts/Poppins/Poppins-SemiBold.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins Bold Font';
  font-style: normal;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local('Soho Bold Font'),
    url('../fonts/Poppins/Poppins-Bold.ttf') format('opentype'); /* will be preloaded */
}

@font-face {
  font-family: 'Poppins Black Font';
  font-style: normal;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local('Soho Black Font'),
    url('../fonts/Poppins/Poppins-Black.ttf') format('opentype'); /* will be preloaded */
}

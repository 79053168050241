$bg-light-blue: #eaf1f8;
$bg-white: #fff;

$text-dark-blue: #15396a;
$text-navy-blue: #05396e;
$text-blue: #005e9e;
$text-white: #fff;
$text-grey: #8a93a5;
$text-red: #ed1c24;
$text-black: #000;
$text-dark-grey: #495366;

$border-grey: #bfbfbf;
$border-light-grey: #dedede;

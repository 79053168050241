@import "../modules/grid";
@import "../modules/config-font";
@import "../modules/font";
@import "../modules/color";
@import "../modules/card";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  * {
    box-sizing: border-box;
    @include poppins-normal;
    word-wrap: break-word;
  }
  font-size: 15px;
  iframe {
    display: none !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  .hide {
    display: none !important;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-capitalise {
    text-transform: capitalize;
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1500;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .loader_wrapper {
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.65);
      backdrop-filter: blur(5px);
      width: 100%;
      height: 100%;
      @include vertical-center;
      flex-direction: column;
      img {
        width: 25%;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
        background-color: rgba(72, 72, 72, 0.65);
        backdrop-filter: blur(5px);
        padding: 20px;
        border-radius: 22px;
        // border: 1px solid $text-white;
      }
      p {
        @include poppins-bold;
        font-size: 2rem;
        color: $text-white;
      }
    }
  }
  .c2p3d-logo {
    img {
      width: 80%;
      margin-bottom: -15px;
    }
    .sub-logo {
      background-color: #1b5588;
      color: $text-white;
      @include poppins-bold;
      font-size: 8px;
      width: auto;
      display: inline-block;
      text-align: center;
      border-radius: 22px;
      padding: 1px 10px;
    }
    margin-bottom: 20px;
  }
  .sanchayplus-logo {
    .HDFCLIFE_logo {
      width: 90px;
      height: 25px;
      background-size: 100%;
      display: block;
      float: left;
      margin-top: 1px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='86' height='19'%3E%3Cdefs%3E%3Cpath id='a' d='M0 .4h85.1v18H.1z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%231B5388' d='M61.6 5c1.2 0 2.1-.8 2.1-1.8s-1-1.7-2.1-1.7c-1.2 0-2.1.8-2.1 1.7 0 1 1 1.8 2.1 1.8'/%3E%3Cg transform='translate(0 .1)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%231B5388' d='M58.7 15.2c0-.5-.4-1-1-1h-5.2V5.8 3.7c0-1.3-1.8-1.7-3-1.8-.9 0-.8.8-.8.8v12.9c0 .8.9 1 .9 1h9v-1.4m12.7-8.8c-.4-4.4 2.1-4.2 3-4 .5 0 .5-.3.4-.4a1.3 1.3 0 0 0-.4-.9c-1-.8-2.7-.7-2.7-.7-3 0-4 2.3-4 4.2l-.1 1.8h-1c-.5 0-.7.5-.7.8V8h1.6v4.8c-3.3 3-4 1.3-4 .6V8c0-1.3-1.8-1.7-3-1.8-1 0-.9.8-.9.8v6.4c0 2.3 1 3.3 3 3.3 2.1 0 4.2-1.7 5-2.6a148 148 0 0 0 0 3.5c0 .9 1 .8 1 .8 2.9 0 2.8-2.1 2.8-2.1V8h1.8c.4 0 1-.2 1-.9v-.7h-2.8m13.3 7.3c-1 .6-2.3 1.2-4.1 1.2-1.8 0-3-1-3-3.2 0-1 .5-3.3 2.8-3.3.9 0 1.4.6 1.4 1.3 0 1-1.1 2.2-3.5 2.3.4 1 1.5 1.3 2.4 1.3 1.4 0 4.6-1 4.3-4-.2-2.4-2.4-3.1-4.2-3.1-4.2 0-7.5 2.6-7.2 6.2.3 3.9 3.8 4.4 6 4.4 2.4 0 4.5-1 5.3-2.1l.3-.7c0-.3-.2-.5-.5-.3' mask='url(%23b)'/%3E%3Cpath fill='%23CF252B' d='M7.7 16.6v-5H4v5H0V4.2h3.9V9h3.8V4.2h4v12.4h-4' mask='url(%23b)'/%3E%3C/g%3E%3Cpath fill='%23CF252B' d='M18.2 17c-1.7 0-3-.1-4.7-.3V4.3a46.6 46.6 0 0 1 4.7-.2c4.3 0 6.2 1.7 6.2 5.9v1c0 4.3-1.9 6-6.2 6zm2.4-7c0-2.6-.5-3.2-2.3-3.2h-1v7.4h1c1.8 0 2.3-.7 2.3-3.2v-1zM30 7v2.2h4.6v2.5H30v5h-4V4.3h9V7h-5m11.5 10c-3.9 0-5.8-1.4-5.8-6v-1c0-4.6 2-6 5.8-6 1 0 3 .2 3.6.4V7a42.7 42.7 0 0 0-3.1-.1c-1.6 0-2.4.4-2.4 3v1.2c0 2.6.8 3 2.4 3a43 43 0 0 0 3 0v2.5c-.6.2-2.5.4-3.5.4'/%3E%3C/g%3E%3C/svg%3E");
    }
    .red {
      color: #cf252b;
      display: inline-block;
      @include poppins-bold;
      padding-left: 2px;
      font-size: 17px;
      margin-top: -2px;
    }
    .blue {
      color: #005e9e;
      display: inline;
      @include poppins-bold;
    }
    .logo-desc {
      color: #8f8f8f;
      font-size: 0.6rem;
      @include poppins-normal;
      font-weight: 300;
      text-align: left;
      font-weight: 600;
      float: none;
      clear: both;
    }
  }
  .product-logo {
    font-size: 1rem;

    .red {
      color: #cf252b;
      @include poppins-bold;
      padding-right: 5px;
    }
    .blue {
      color: #1b5588;
      @include poppins-bold;
      padding-right: 5px;
    }
    .title {
      font-size: 1.4rem;
      &.lg-logo {
        span {
          font-size: 1rem;
        }
      }
    }
    .sub-text {
      font-size: 14px;

      span {
        @include poppins-bold;
        min-width: 30%;
        color: $text-dark-grey;
        display: inline-block;
        font-size: 11px;
        &:nth-child(2) {
          border-left: 1px solid $text-dark-grey;
          padding-left: 25px;
        }
      }
      &:nth-child(3) {
        span {
          @include poppins-normal;
          font-size: 11px;
        }
      }
    }
  }
  .empty-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    flex-direction: column;
    div {
      i {
        font-size: 6rem;

        color: $text-red;
      }
      font-size: 20px;
      line-height: 2rem;
      color: $text-blue;
      @include poppins-semi-bold;
      background-color: $bg-white;
      padding: 1rem;
      border-radius: 10px;
      &.icon-cntnr {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  body {
    font-size: 9px;
    .hidden-xs {
      display: none !important;
    }
    .empty-page {
      font-size: 1.2rem;
    }
    .loader {
      .loader_wrapper {
        img {
          width: 50%;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
    .Toastify {
      .Toastify__toast-container {
        .Toastify__toast {
          .Toastify__toast-body {
            font-size: 14px;
          }
          button {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .hidden-md {
    display: none !important;
  }
}

@mixin poppins-black {
  font-family: 'Poppins Black Font';
}
@mixin poppins-bold {
  font-family: 'Poppins Bold Font';
}
@mixin poppins-regular {
  font-family: 'Poppins Regular Font';
}
@mixin poppins-semi-bold {
  font-family: 'Poppins SemiBold Font';
}
@mixin poppins-normal {
  font-family: 'Poppins Normal Font';
}
